<template>
  <div class="pir-logs mt-4 ml-8 mr-8">
    <v-container fluid>
      <v-row>
        <v-col cols="4">
          <h1 class="display-2 mb-5">PIR Logs</h1>
        </v-col>
      </v-row>
      <v-card color="default" outlined class="elevation-0">
        <v-card-text>
          <remote-data-table
            v-if="renderDatatable"
            :url="'backoffice/pir-logs'"
            :search-enabled="true"
            :headers="headers"
            :filters="filters"
            :sort-by="'-created_at'"
            ref="pirLogsDatatable"
          >
            <template v-slot:item.created_at="{ item }">
              {{ item.created_at | formatDateTime }}
            </template>
            <template v-slot:item.type="{ item }">
              {{ item.type.toUpperCase() }}
            </template>
            <template v-slot:item.room="{ item }">
              {{ item.room.name }} ({{ item.room.number }})
            </template>
          </remote-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import RemoteDataTable from "@/components/RemoteDataTable";
import moment from "moment";

export default {
  components: { RemoteDataTable },

  data: () => ({
    renderDatatable: false,
    headers: [
      { text: "ID", align: "left", sortable: true, value: "id" },
      {
        text: "Erstellt am",
        align: "left",
        sortable: true,
        value: "created_at"
      },
      { text: "Typ", align: "left", sortable: true, value: "type" },
      { text: "Raum", align: "left", sortable: true, value: "room" }
    ],
    filters: [
      {
        type: "select",
        field: "room_id",
        label: "Raum",
        values: [],
        default: null
      },
      {
        type: "select",
        field: "type",
        label: "Typ",
        values: [
          { text: `Ein`, value: "on" },
          { text: "Aus", value: "off" }
        ],
        default: null
      },
      {
        type: "datepicker",
        field: "date",
        label: "Datum",
        default: moment()
      }
    ]
  }),

  created() {
    this.fetchRooms();
  },

  methods: {
    /**
     * Fetch rooms from server
     */
    fetchRooms() {
      this.$api.http.get(`backoffice/rooms?_per_page=99999`).then(response => {
        this.filters[0].values = response.data.data.map(room => {
          return {
            text: `${room.name}`,
            value: room.id
          };
        });
        this.filters[0].default = parseInt(this.$route.query._room);
        this.renderDatatable = true;
      });
    }
  }
};
</script>

<style></style>
