<template>
  <div class="siport-logs mt-4 ml-8 mr-8">
    <v-container fluid>
      <v-row>
        <v-col cols="4">
          <h1 class="display-2 mb-5">SIPORT Profiles</h1>
        </v-col>
      </v-row>
      <v-card color="default" outlined class="elevation-0">
        <v-card-text>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    ID
                  </th>
                  <th class="text-left">
                    Profil
                  </th>
                  <th class="text-left">
                    Name
                  </th>
                  <th class="text-right">
                    Gültig von
                  </th>
                  <th class="text-right">
                    Gültig bis
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(log, index) in logs" :key="index">
                  <td>{{ log.id }}</td>
                  <td>{{ log.profile }}</td>
                  <td>{{ log.first_name }} {{ log.last_name }}</td>
                  <td class="text-right">
                    {{ log.valid_from | formatDateTime }}
                  </td>
                  <td class="text-right">
                    {{ log.valid_until | formatDateTime }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data: () => ({
    logs: []
  }),

  created() {
    this.fetchSiportLogs();
  },

  methods: {
    async fetchSiportLogs() {
      const response = await this.$api.http.get("backoffice/siport-logs");
      this.logs = response.data;
    }
  }
};
</script>

<style></style>
