<template>
  <div class="logs">
    <v-container fluid>
      <v-card class="elevation-0">
        <v-tabs
          v-model="currentTab"
          background-color="grey lighten-2"
          @change="updateURL()"
          color="primary"
        >
          <v-tab v-for="item in items" :key="item.title">
            {{ item.title }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="currentTab">
          <v-tab-item v-for="item in items" :key="item.title">
            <component :is="item.component"></component>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import SystemLogs from "./SystemLogs.vue";
import CrestronLogs from "./CrestronLogs.vue";
import PirLogs from "./PirLogs.vue";
import SiportLogs from "./SiportLogs.vue";
import EventManagementLogs from "./EventManagementLogs.vue";

export default {
  name: "logs",

  data: () => ({
    currentTab: null,
    items: [
      {
        title: "System",
        component: SystemLogs,
        url: "/logs/system"
      },
      {
        title: "Crestron",
        component: CrestronLogs,
        url: "/logs/crestron"
      },
      {
        title: "PIR",
        component: PirLogs,
        url: "/logs/pir"
      },
      {
        title: "SIPORT",
        component: SiportLogs,
        url: "/logs/siport"
      },
      {
        title: "BP Event",
        component: EventManagementLogs,
        url: "/logs/event-management"
      }
    ]
  }),

  watch: {
    $route(to, from) {
      this.setTab(to.params.module);
    }
  },

  created() {
    this.setTab(this.$route.params.module);
  },

  methods: {
    /**
     * Update URL in browser.
     */
    updateURL() {
      this.$router.replace(this.items[this.currentTab].url);
    },
    /**
     * Set active tab.
     */
    setTab(module) {
      let foundItems = this.items.filter(
        item => item.url === "/logs/" + module
      );
      if (foundItems.length > 0) {
        this.currentTab = this.items.indexOf(foundItems[0]);
      }
    }
  }
};
</script>

<style lang="scss">
.logs {
  margin-left: -13px;
  margin-right: -13px;
  margin-top: -13px;

  .v-window-item {
    padding-top: 10px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

h1 {
  margin-bottom: 2rem;
}
</style>
