<template>
  <div class="pir-logs mt-4 ml-8 mr-8">
    <v-container fluid>
      <v-row>
        <v-col cols="4">
          <h1 class="display-2 mb-5">BP Event Logs</h1>
        </v-col>
      </v-row>
      <v-card color="default" outlined class="elevation-0">
        <v-card-text>
          <remote-data-table
            :url="'backoffice/event-management-logs'"
            :search-enabled="true"
            :headers="headers"
            :filters="filters"
            :sort-by="'-created_at'"
          >
            <template v-slot:item.created_at="{ item }">
              {{ item.created_at | formatDateTime }}
            </template>
            <template v-slot:item.type="{ item }">
              {{ item.type.toUpperCase() }}
            </template>
            <template v-slot:item.room="{ item }">
              {{ item.room.name }} ({{ item.room.number }})
            </template>
          </remote-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import RemoteDataTable from "@/components/RemoteDataTable";
import moment from "moment";

export default {
  components: { RemoteDataTable },

  data: () => ({
    headers: [
      { text: "ID", align: "left", sortable: true, value: "id" },
      {
        text: "Erstellt am",
        align: "left",
        sortable: true,
        value: "created_at"
      },
      { text: "Typ", align: "left", sortable: true, value: "type" },
      { text: "Nachricht", align: "left", sortable: true, value: "message" }
    ],
    filters: [
      {
        type: "select",
        field: "type",
        label: "Typ",
        values: [
          { text: `Import`, value: "import" },
          { text: `Export`, value: "export" }
        ],
        default: null
      },
      {
        type: "datepicker",
        field: "date",
        label: "Datum",
        default: moment()
      }
    ]
  })
};
</script>

<style scoped></style>
