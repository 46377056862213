<template>
  <div class="system-logs mt-4 ml-8 mr-8">
    <v-container fluid>
      <v-row>
        <v-col cols="4">
          <h1 class="display-2 mb-5">System Logs</h1>
        </v-col>
      </v-row>
      <v-card color="default" outlined class="elevation-0">
        <v-card-text>
          <remote-data-table
            :url="'backoffice/system-logs'"
            :search-enabled="true"
            :headers="headers"
            :filters="filters"
            :sort-by="'-created_at'"
            ref="systemLogsDatatable"
          >
            <template v-slot:item.created_at="{ item }">
              {{ item.created_at | formatDateTime }}
            </template>
            <template v-slot:item.system="{ item }">
              {{ item.system.toUpperCase() }}
            </template>
            <template v-slot:item.type="{ item }">
              <v-chip v-if="item.type === 'info'" color="info" label small>
                Info
              </v-chip>
              <v-chip
                v-else-if="item.type === 'error'"
                color="error"
                label
                small
              >
                Fehler
              </v-chip>
              <v-chip v-else color="warning" label small>
                Warnung
              </v-chip>
            </template>
          </remote-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import RemoteDataTable from "@/components/RemoteDataTable";
import moment from "moment";

export default {
  components: { RemoteDataTable },

  data: () => ({
    headers: [
      { text: "ID", align: "left", sortable: true, value: "id" },
      {
        text: "Erstellt am",
        align: "left",
        sortable: true,
        value: "created_at"
      },
      { text: "System", align: "left", sortable: true, value: "system" },
      { text: "Nachricht", align: "left", sortable: false, value: "message" },
      { text: "Typ", align: "left", sortable: true, value: "type" }
    ],
    filters: [
      {
        type: "datepicker",
        field: "date",
        label: "Datum",
        default: moment()
      }
    ]
  }),

  methods: {}
};
</script>

<style></style>
